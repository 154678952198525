import React from 'react';
import { LandingPage } from 'components/landing';
import SEO from 'components/SEO';

export default function index() {
  return (
    <div>
      <SEO />
      <LandingPage />;
    </div>
  );
}
