import React from 'react';
import { navigate } from 'gatsby';
import { Grid, Box, Button, Typography } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import monash_logo from './imgs/monash_logo.png';
import { buttonColour } from '../../theme';

export const Footer = withStyles((theme) => ({
  article: {
    textAlign: 'center',
    padding: theme.spacing(4, 8, 8, 8),
    backgroundColor: '#e9f7fa',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4, 2, 4, 2),
    },
  },
  button: {
    color: '#ffffff',
    backgroundColor: buttonColour,
  },
  title: {
    marginBottom: theme.spacing(6),
  },
  image: {
    width: 600,
  },
}))((props: WithStyles) => {
  const { classes } = props;
  return (
    <article className={classes.article}>
      <Grid container spacing={2}>
        <Grid item xs={12} container justify="center">
          <Grid item xs={12} md={8} className={classes.title}>
            <Typography variant="h5" color="primary">
              Contribute to the important research on parenting & teen mental health by Monash University.
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="center">
              <img src={monash_logo} alt="Logo" className={classes.image} />
            </Box>
          </Grid>

          <Grid item xs={12} md={8}>
            <Box mt={4} mb={4}>
              <Button variant="contained" className={classes.button} onClick={() => navigate('/dashboard')}>
                Join Now
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </article>
  );
});
