import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import target from './imgs/target.png';
import { ExtLink } from 'components';

export const TargetBlock = withStyles((theme) => ({
  article: {
    padding: theme.spacing(4, 8, 0, 8),
    backgroundColor: '#f3fcfd',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      padding: theme.spacing(4, 2, 0, 2),
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  heroList: {
    textAlign: 'left',
    padding: theme.spacing(0, 3),
    margin: theme.spacing(0),
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      listStylePosition: 'inside',
    },
  },
}))((props: WithStyles) => {
  const { classes } = props;
  return (
    <article className={classes.article}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} container alignContent="center">
          <Grid item xs={12}>
            <Box my={4}>
              <Typography variant="h5" color="primary">
                Who is this program for?
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" paragraph>
              Parents/guardians of teenagers aged 12 to 17, who are
            </Typography>
            <Box my={2}>
              <Typography variant="body1" component="span">
                <ul className={classes.heroList}>
                  <li>residing in Australia,</li>
                  <li>fluent in English, and</li>
                  <li>have regular internet access.</li>
                </ul>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" paragraph>
              PiP+ is designed to help parents whose teenagers are not yet experiencing difficulties with depression and
              anxiety. If your teenager is experiencing such difficulties, you are still welcome to take part, but we
              also recommend you seek help from a mental health professional.
            </Typography>

            <Typography variant="body1" paragraph>
              If you are a parent of children younger than 12 or older than 17, or a professional who wants to check out
              the program before recommending it to parents, you are also welcome to sign up!
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Box my={4}>
              <Typography variant="h5" color="primary">
                Does your teen experience school refusal?
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" paragraph>
              If your teen struggles to attend school due to anxiety, depression, or emotional distress (sometimes
              called ‘school refusal’, ‘school can’t’, or ‘school phobia’), check out the PiP-Education program{' '}
              <ExtLink href="https://pip-ed.web.app/" color="primary">
                here
              </ExtLink>
              .
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} style={{ alignSelf: 'flex-end' }}>
          <Box display="flex">
            <img src={target} alt="Target" className={classes.image} />
          </Box>
        </Grid>
      </Grid>
    </article>
  );
});
