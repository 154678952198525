import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { primaryColour } from '../../theme';

const modules = [
  {
    title: 'Parenting through the pandemic',
    description:
      'The COVID-19 pandemic has brought with it many new challenges for parents and families. This module aims to support you and your teen to navigate the ‘new normal’ and respond to the challenges of the pandemic with confidence.',
  },
  {
    title: 'From surviving to thriving',
    description:
      'We all feel anxious at times, and it’s important for teenagers to learn to manage their everyday anxiety. ‘From surviving to thriving: Helping your teenager deal with anxiety’ will talk about ways that you can support your teenager to cope with the fears and worries they will inevitably face.',
  },
  {
    title: 'Connect',
    description:
      'Connect is about empowering you to change your behaviour so you can positively influence your teenager. We’ll talk about ways to build your relationship and communication with your teenager.',
  },
  {
    title: "When things aren't okay",
    description:
      'It can be hard for parents to tell whether changes in their teenager’s behaviour are a normal part of adolescence, or a more serious problem like depression or anxiety. In this module, we’ll take a closer look at depression and clinical anxiety, including when and how to seek professional help.',
  },
  {
    title: 'Raising good kids into great adults',
    description:
      'Teenagers need the adults around them to set clear boundaries and expectations for their behaviour. This module will help you to establish clear expectations that your teen is likely to follow.',
  },
  {
    title: 'Good friends = supportive relationships',
    description:
      'As teenagers begin to place greater emphasis on their peers, parents still play an important role in helping their teens to develop social skills. This module helps you to support your teen to develop social skills across a variety of settings.',
  },
  {
    title: 'Calm versus conflict',
    description:
      'Parents play an important role in helping their teenagers learn to manage conflict. ‘Calm versus conflict’ aims to help you manage conflict at home, to create a safe and supportive environment for your teenager.',
  },
  {
    title: 'Nurture roots & inspire wings',
    description:
      '‘Nurture roots’ is all about finding a balanced way to stay involved in your teenager’s life while also allowing them to ‘leave the nest’ and develop age-appropriate independence.',
  },
  {
    title: 'Good health habits for good mental health',
    description:
      'Getting enough sleep, eating well, being physically active, and not using alcohol or drugs can protect our physical and mental health. This module is all about helping you to support your teenager to develop good health habits.',
  },
  {
    title: 'Partners in problem solving',
    description:
      'Problems are a part of life. As teenagers mature, they need to learn strategies to tackle the problems they will face throughout their life. ‘Partners in problem solving’ focusses on how you as a parent can support your teenager to develop good problem-solving skills.',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'left',
    padding: theme.spacing(0, 12),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 8),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 2),
    },
  },
  bullet: {
    marginTop: theme.spacing(3.2),
    borderColor: primaryColour,
    borderTopStyle: 'solid',
  },
  accordion: {
    backgroundColor: 'transparent',
  },
  heading: {
    fontSize: theme.typography.pxToRem(17),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(15),
    },
  },
  description: {
    fontSize: theme.typography.pxToRem(15),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(13),
    },
  },
  summary: {
    flexDirection: 'row',
    height: '50px',
    padding: '0 16px',
    '&.Mui-expanded': {
      minHeight: 0,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 'auto',
    },
  },
}));

export default function ModuleAccordian() {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Grid container spacing={matches ? 0 : 2} className={classes.root}>
      {modules.map((topic, idx) => {
        return (
          <Grid key={idx} item xs={12} sm={6} container>
            <Grid item xs={1} className={classes.bullet} />

            <Grid item xs={11}>
              <Accordion elevation={0} className={classes.accordion}>
                <AccordionSummary
                  className={classes.summary}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <Typography className={classes.heading}>{topic.title}</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography className={classes.description}>{topic.description}</Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}
