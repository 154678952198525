import React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Box, Container, Grid, Hidden, Paper, Button, Typography } from '@material-ui/core';
import { cyan } from '@material-ui/core/colors';
import { Light, Studio, Earphone } from './assets';
import { ExtLink } from 'components';

export const RoofBlock = withStyles((theme) => ({
  roof: {
    borderTopStyle: 'solid',
    borderTopWidth: 6,
    borderTopColor: '#333',
    height: 125,
    background: `linear-gradient(180deg, ${cyan[400]} 0%, ${cyan[50]} 100%)`,
    overflow: 'hidden',
  },
  lightItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  light: {
    width: 359,
    height: 246,
  },
  card: {
    backgroundColor: cyan[100],
    padding: theme.spacing(3, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      height: `calc(100% - 48px)`,
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3, 1),
    },
  },
  list: {
    padding: 0,
    listStylePosition: 'inside',
  },
}))((props: WithStyles) => {
  const { classes } = props;
  return (
    <article>
      <Box className={classes.roof}>
        <Container>
          <Grid container spacing={2}>
            <Grid item sm={12} md={6} className={classes.lightItem}>
              <Light className={classes.light} />
            </Grid>
            <Hidden smDown>
              <Grid item sm={12} md={6} className={classes.lightItem}>
                <Light className={classes.light} />
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </Box>

      <Box bgcolor={cyan[50]} p={3}>
        <Container>
          <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
              <Paper className={classes.card} elevation={0}>
                <Studio />
                <Typography color="primary" variant="h6" align="center" gutterBottom>
                  Evidence-based parenting program
                </Typography>

                <Box flex={1} mt={2}>
                  <Typography color="primary" variant="body1" align="center">
                    PiP+ content is based on:
                  </Typography>

                  <Typography component="span" variant="body1" align="center" className={classes.list}>
                    <ul style={{ padding: 0, margin: 0 }}>
                      <li>High-quality research evidence and international expert consensus</li>
                      <li>
                        Parenting guidelines:{' '}
                        <i>
                          Reducing teenagers’ risk of depression and anxiety disorders: Strategies for parents during
                          the COVID-19 pandemic
                        </i>
                      </li>
                    </ul>
                  </Typography>
                </Box>

                <Box mt={2} justifySelf="flex-end">
                  <Button color="primary" href="/resources" target="__blank">
                    More Resources
                  </Button>
                </Box>
              </Paper>
            </Grid>

            <Grid item sm={12} md={6}>
              <Paper className={classes.card} elevation={0}>
                <Earphone />
                <Typography color="primary" variant="h6" align="center" gutterBottom>
                  1,500+ families helped
                </Typography>

                <Box flex={1} mt={2}>
                  <Typography variant="body1" paragraph align="center">
                    Our research trials with over 1,500 Australian parents show that PiP is effective, with benefits for
                    both parents and teens.
                  </Typography>

                  <Typography variant="body1" paragraph align="center">
                    You can read more about the evidence for the program in the{' '}
                    <ExtLink href="/about">About Us</ExtLink> page or feel free to contact us.
                  </Typography>
                </Box>

                <Box mt={2} justifySelf="flex-end">
                  <Button color="primary" href="/contact" target="__blank">
                    Contact us
                  </Button>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </article>
  );
});
