import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import responsive from './imgs/responsive.png';
import interactive from './imgs/interactive.png';
import join from './imgs/join.png';

export const MoreDetailBlock = withStyles((theme) => ({
  article: {
    padding: theme.spacing(4, 8, 4, 8),
    backgroundColor: '#f3fcfd',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      padding: theme.spacing(4, 2, 4, 2),
    },
  },
  image: {
    width: '70%',
    height: '70%',
    objectFit: 'cover',
  },
  list: {
    padding: 0,
    listStylePosition: 'inside',
  },
  orderFirst: {
    order: 3,
    margin: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down('sm')]: {
      order: 4,
      margin: theme.spacing(4, 0, 0, 0),
    },
  },
  orderSecond: {
    order: 4,
    alignSelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      order: 3,
    },
  },
}))((props: WithStyles) => {
  const { classes } = props;
  return (
    <article className={classes.article}>
      <Grid container spacing={2}>
        {/* Fits Lifestyle Section */}
        <Grid item xs={12} md={6} style={{ order: 1 }}>
          <Box display="flex" justifyContent="center">
            <img src={responsive} alt="Responsive" className={classes.image} />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} container alignContent="center" style={{ order: 2 }}>
          <Grid item xs={12} style={{ marginBottom: '18px' }}>
            <Typography variant="h5" color="primary">
              Fits your lifestyle
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography component="span" variant="body1" className={classes.list}>
              <ul style={{ padding: 0, margin: 0 }}>
                <li>
                  Only <b>15-25</b> minutes per module
                </li>
                <li>Complete the program at your own pace</li>
                <li>Both desktop and mobile versions available</li>
                <li>
                  Get a copy of the guidelines{' '}
                  <i>
                    “Reducing teenagers’ risk of depression and anxiety disorders: Strategies for parents during the
                    COVID-19 pandemic”
                  </i>{' '}
                  sent to your email
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>

        {/* Fun and Interactive Section */}
        <Grid item xs={12} md={6} container alignContent="center" className={classes.orderFirst}>
          <Grid item xs={12} style={{ marginBottom: '18px' }}>
            <Typography variant="h5" color="primary">
              Fun and interactive
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography component="span" variant="body1" className={classes.list}>
              <ul style={{ padding: 0, margin: 0 }}>
                <li>Learn practical strategies through interactive activities and video-based scenarios</li>
                <li>Hear what teenagers have to say by listening to their audio recordings</li>
                <li>Apply your module learnings through weekly goals</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} className={classes.orderSecond}>
          <Box display="flex" justifyContent="center">
            <img src={interactive} alt="Interactive" className={classes.image} />
          </Box>
        </Grid>

        {/* Join Section */}
        <Grid item xs={12} md={6} style={{ order: 5 }}>
          <Box display="flex" justifyContent="center">
            <img src={join} alt="Join Now" className={classes.image} />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} container alignContent="center" style={{ order: 6 }}>
          <Grid item xs={12} style={{ marginBottom: '18px' }}>
            <Typography variant="h5" color="primary">
              Free to join
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" paragraph>
              Joining PiP+ won’t cost you a cent! Just click on the “Join Now” button and register using either your
              Facebook account or your email address.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </article>
  );
});
