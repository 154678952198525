import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import wellness from './imgs/7plus_wellness.png';

export const StoryBlock = withStyles((theme) => ({
  article: {
    padding: theme.spacing(4, 12, 4, 12),
    backgroundColor: '#f5f7f7',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4),
    },
  },
  video: {
    width: '560px',
    height: '315px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '56vw',
    },
  },
  image: {
    width: '70%',
    height: '70%',
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      width: '40%',
      height: '40%',
    },
  },
}))((props: WithStyles) => {
  const { classes } = props;
  return (
    <article className={classes.article}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} container alignContent="center">
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography variant="body1" paragraph>
              Listen to the stories of previous PiP parents and their teenagers featured in The House of Wellness.
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <iframe
              src="https://player.vimeo.com/video/624931363?h=368f463954&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              className={classes.video}
              title="PiP+ - House of Wellness"></iframe>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4} style={{ alignSelf: 'center' }}>
          <Box display="flex" justifyContent="center">
            <img src={wellness} alt="Target" className={classes.image} />
          </Box>
        </Grid>
      </Grid>
    </article>
  );
});
