import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import delivery from './imgs/delivery.png';

export const StoreBlock = withStyles((theme) => ({
  article: {
    textAlign: 'center',
    padding: theme.spacing(8),
    backgroundColor: '#e9f7fa',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4, 2, 4, 2),
    },
  },
  icon: {
    width: '80px',
    [theme.breakpoints.down('sm')]: {
      width: '65px',
    },
  },
}))((props: WithStyles) => {
  const { classes } = props;
  return (
    <article className={classes.article}>
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} sm={8}>
          <img src={delivery} alt="Successful Delivery" className={classes.icon} />
        </Grid>

        <Grid item xs={12} sm={8}>
          <Typography variant="h5" color="primary">
            What’s in store for you?
          </Typography>
        </Grid>

        <Grid item xs={12} sm={8}>
          <Typography variant="body1" paragraph>
            PiP+ aims to empower you to make sense of adolescence and to parent your teenager with confidence. The
            program includes practical, evidence-based and expert-endorsed strategies designed to build your skills and
            confidence in ways that may reduce your teen's risk of depression and anxiety problems.
          </Typography>
        </Grid>
      </Grid>
    </article>
  );
});
