import React from 'react';
import { navigate } from 'gatsby';
import { Container, Grid, Typography, Box, Button } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import family from './imgs/family.png';
import { Heading } from '../../styles';
import { buttonColour } from '../../theme';

export const Hero = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
      textAlign: 'center',
    },
  },
  button: {
    color: '#ffffff',
    backgroundColor: buttonColour,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  heading: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8rem',
      '& > sup': {
        fontSize: '1.1rem',
      },
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3rem',
      '& > sup': {
        fontSize: '0.9rem',
      },
    },
  },
}))((props: WithStyles) => {
  const { classes } = props;
  const theme = useTheme();
  const smMatches = useMediaQuery(theme.breakpoints.down('sm'));
  const xsMatches = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Container className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Heading className={classes.heading}>
            Partners in Parenting PLUS<sup>PiP+</sup>
          </Heading>

          <Typography variant="body1">
            Partners in Parenting PLUS (PiP+) is the new and updated version of the <b>FREE</b> award-winning,
            evidence-based parenting program designed to help you raise your teenager with confidence.
          </Typography>

          <Box my={4}>
            <Grid container spacing={2} justify={xsMatches ? 'center' : 'flex-start'}>
              <Grid item>
                <Button variant="contained" className={classes.button} onClick={() => navigate('/auth/signup')}>
                  Join Now
                </Button>
              </Grid>

              {smMatches && (
                <Grid item>
                  <Button variant="contained" className={classes.button} onClick={() => navigate('/auth/signin')}>
                    Sign in
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} md={7} style={{ alignSelf: 'flex-end' }}>
          <Box display="flex">
            <img src={family} alt="Family" className={classes.image} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
});
