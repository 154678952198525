import React from 'react';
import { cyan } from '@material-ui/core/colors';
import { SitePage } from '../layout';
import { Hero } from './Hero';
import { RoofBlock } from './RoofBlock';
import { TargetBlock } from './TargetBlock';
import { StoryBlock } from './StoryBlock';
import { StoreBlock } from './StoreBlock';
import { StockDetailBlock } from './StockDetailBlock';
import { CarouselBlock } from './CarouselBlock';
import { MoreDetailBlock } from './MoreDetailBlock';
import { Footer } from './Footer';

export const LandingPage = () => (
  <SitePage color={cyan[50]}>
    <Hero />
    <RoofBlock />
    <TargetBlock />
    <StoryBlock />
    <StoreBlock />
    <StockDetailBlock />
    <CarouselBlock />
    <MoreDetailBlock />
    <Footer />
  </SitePage>
);
