import React, { CSSProperties } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import quote from './imgs/quote.png';
import { primaryColour } from '../../theme';

const carouselList = [
  {
    title: 'Mum of a 12-year-old boy',
    content:
      'The course actually reminded me to trust the groundwork I had already laid with my kids and trust them to find a way through - which my son did! It was only a month or so before the boy I recognised returned and we were able to get our communication strategies back on track.',
  },
  {
    title: 'Dad with a Vietnamese background',
    content:
      'This is a good program especially for parents from a migrant background. This program gave me insight about parenting in Australian context.',
  },
  {
    title: 'Step-mum of a 14-year-old girl',
    content:
      'PiP kept me reflecting on my parenting as different situations and challenges arise during this very unique year - 2020.  I love the emails that remind me to get back online and do another unit every fortnight or so.',
  },
  {
    title: 'Dad of a 16-year-old boy',
    content:
      'I feel that it did reinforce ideas I already had about parenting and encouraged me to be really open with my son so he felt comfortable discussing his anxiety with me. Open communication is so important and the program encouraged this.',
  },
];

export const CarouselBlock = withStyles((theme) => ({
  article: {
    textAlign: 'center',
    padding: theme.spacing(4, 8, 4, 8),
    backgroundColor: '#f2ebdf',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4, 2, 0, 2),
    },
  },
  slide: {
    marginBottom: theme.spacing(4),
  },
  icon: {
    width: '80px !important',
    height: '80px',
    [theme.breakpoints.down('sm')]: {
      width: '55px !important',
      height: '55px',
    },
  },
}))((props: WithStyles) => {
  const { classes } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const arrowStyles: CSSProperties = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 45,
    height: 45,
    cursor: 'pointer',
  };

  const indicatorStyles: CSSProperties = {
    background: '#C4C4C4',
    width: 10,
    height: 10,
    borderRadius: '12px',
    display: 'inline-block',
    margin: '0 8px',
    cursor: 'pointer',
  };

  return (
    <article className={classes.article}>
      <Carousel
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        infiniteLoop={true}
        interval={5000}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <IconButton color="primary" onClick={onClickHandler} style={{ ...arrowStyles, left: matches ? -10 : 15 }}>
              <KeyboardArrowLeftIcon fontSize="large" />
            </IconButton>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <IconButton color="primary" onClick={onClickHandler} style={{ ...arrowStyles, right: matches ? -10 : 15 }}>
              <KeyboardArrowRightIcon fontSize="large" />
            </IconButton>
          )
        }
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          if (isSelected) {
            return (
              <li
                style={{ ...indicatorStyles, background: primaryColour }}
                aria-label={`Selected: ${label} ${index + 1}`}
                title={`Selected: ${label} ${index + 1}`}
              />
            );
          }
          return (
            <li
              style={indicatorStyles}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              title={`${label} ${index + 1}`}
              aria-label={`${label} ${index + 1}`}
            />
          );
        }}>
        {carouselList.map((carousel, idx) => {
          return (
            <Grid key={idx} container spacing={2} justify="center" className={classes.slide}>
              <Grid item xs={12}>
                <img src={quote} alt="Quote" className={classes.icon} />
              </Grid>

              <Grid item xs={10} sm={8} style={{ marginBottom: '18px' }}>
                <Typography variant="h5" color="primary">
                  {carousel.title}
                </Typography>
              </Grid>

              <Grid item xs={10} sm={8}>
                <Typography variant="body1" paragraph>
                  <i>{carousel.content}</i>
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Carousel>
    </article>
  );
});
