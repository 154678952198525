import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import ModuleAccordian from './ModuleAccordion';
import feedback from './imgs/feedback.png';
import chat from './imgs/chat.png';

export const StockDetailBlock = withStyles((theme) => ({
  article: {
    padding: theme.spacing(4, 8, 4, 8),
    backgroundColor: '#f3fcfd',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      padding: theme.spacing(4, 2, 4, 2),
    },
  },
  moduleSection: {
    margin: theme.spacing(4, 4, 0, 4),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(4, 0, 0, 0),
    },
  },
  image: {
    width: '70%',
    height: '70%',
    objectFit: 'cover',
  },
}))((props: WithStyles) => {
  const { classes } = props;
  return (
    <article className={classes.article}>
      <Grid container spacing={2}>
        {/* Personalised Feedback Section */}
        <Grid item xs={12} md={6} style={{ alignSelf: 'flex-end' }}>
          <Box display="flex" justifyContent="center">
            <img src={feedback} alt="Feedback" className={classes.image} />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} container alignContent="center">
          <Grid item xs={12} style={{ marginBottom: '18px' }}>
            <Typography variant="h5" color="primary">
              Get personalised feedback
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" paragraph>
              Your journey starts with an online assessment. This allows us to give you personalised feedback about your
              parenting, your confidence, and your own and your teen's mental wellbeing. Based on this feedback, we'll
              recommend specific modules for you including the new <i>Parenting through the pandemic</i> module!
            </Typography>
          </Grid>
        </Grid>

        {/* Interactive Module Section */}
        <Grid item xs={12} container alignContent="center" className={classes.moduleSection}>
          <Grid item xs={12} md={10} style={{ marginBottom: '18px' }}>
            <Typography variant="h5" color="primary">
              Access up to 10 interactive online modules
            </Typography>
          </Grid>

          <Grid item xs={12} md={10}>
            <Typography variant="body1" paragraph>
              We will recommend you modules based on your personal assessment, but you can also choose the modules you
              want to do, and the order in which you do them.
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} container justify="center">
          <ModuleAccordian />
        </Grid>

        {/* Get Support Section */}
        <Grid item xs={12} md={6} style={{ alignSelf: 'flex-end' }}>
          <Box display="flex" justifyContent="center">
            <img src={chat} alt="PSG Chat" className={classes.image} />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} container alignContent="center">
          <Grid item xs={12} style={{ marginBottom: '18px' }}>
            <Typography variant="h5" color="primary">
              Get support from other PiP+ parents
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" paragraph>
              Get access to the PiP+ online community where you can meet parents who are also raising teenagers, and
              learn from one another’s experiences.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </article>
  );
});
